import React from 'react'
import styled from 'react-emotion'
import { gray } from '../styles/colors'

const Input = styled.input`
  padding: 1rem;
  border-radius: 2px;
  border: 1px solid ${gray};
`

const InputComponent = props => <Input {...props} />

export default InputComponent
