import React from 'react'
import styled from 'react-emotion'
import Input from './input'
import TextArea from './textArea'
import {Button} from './button'
import {navigate} from 'gatsby-link'
import {black} from '../styles/colors'
import {device} from '../styles/media-queries'

const DemoContent = styled.div`
  padding: 1rem;
  @media ${device.laptop} {
    padding: 2rem 3rem;
  }
`
const DemoRequestHeader = styled.h3`
  text-align: center;
`

const HoneyPot = styled.div`
  display: none;
`

const Label = styled.label`
  color: ${black};
  display: block;
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.2em;
  line-height: 1;
  text-transform: uppercase;
`

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: 1rem;
  }
`
function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

class DemoRequestComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleChange = e => {
    this.setState({[e.target.name]: e.target.value})
  }
  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/contact?no-cache=1', {
      method: 'POST',
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error))
  }
  render() {
    return (
      <DemoContent {...this.props}>
        <DemoRequestHeader>Contact Us To Request a Demo</DemoRequestHeader>
        <ContactForm
          data-netlify-honeypot="full-name"
          data-netlify="true"
          name="contact"
          method="POST"
          action="/thanks/"
          onSubmit={this.handleSubmit}
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <HoneyPot>
            <Label htmlFor="full-name">
              Don’t fill out this field if you’re a human.
            </Label>
            <Input type="text" id="full-name" name="full-name" />
          </HoneyPot>
          <Input
            placeholder="Your Name"
            name="name"
            type="text"
            onChange={this.handleChange}
          />
          <Input
            placeholder="Your Company’s Name"
            name="companyName"
            type="text"
            onChange={this.handleChange}
          />
          <Input
            placeholder="Email"
            name="email"
            type="text"
            onChange={this.handleChange}
          />
          <Input
            placeholder="Phone Number"
            name="phoneNumber"
            type="text"
            onChange={this.handleChange}
          />
          <TextArea
            placeholder="Your Message"
            name="message"
            onChange={this.handleChange}
          />
          <Button type="submit">Submit</Button>
        </ContactForm>
      </DemoContent>
    )
  }
}
export default DemoRequestComponent
